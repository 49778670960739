import { useEffect, useRef, useState } from "react";
import {
  FaEnvelope,
  FaGithub,
  FaMedium,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "./App.css";
import { Content, ContentType, myContent } from "./content";
import pfp from "./pfp.png";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const Mirror = () => {
  const width = 14;
  const height = 18;
  const radius = 7;

  // Define the path
  const pathData = `
    M${radius},0
    h${width - 2 * radius} 
    a${radius},${radius} 0 0 1 ${radius},${radius} 
    v${height - radius} 
    h${-width} 
    v${-height + radius} 
    a${radius},${radius} 0 0 1 ${radius},${-radius}
    Z`;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} fill="black" />
    </svg>
  );
};

function LinkComponent({ data }: { data: Content }) {
  const Icon =
    data.type === ContentType.MEDIUM
      ? FaMedium
      : data.type === ContentType.YOUTUBE
      ? FaYoutube
      : data.type === ContentType.MIRROR
      ? Mirror
      : FaTwitter;
  return (
    <a
      className="flex flex-col gap-1 px-2 padding-1 border-stone-800 hover:bg-gray-100 hover:scale-105 transition-all"
      href={data.link}
      target="_blank"
      rel="noreferrer"
    >
      <span className="w-4/5 text-left font-serif text-sm">{data.name}</span>
      <div className="flex gap-1 items-center">
        <Icon className="text-xl" color="#413736" />
        <p className="text-xs text-slate-600">
          {months[data.date.getMonth()]} {data.date.getFullYear()}
        </p>
      </div>
    </a>
  );
}

function App() {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const checkOverflow = () => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      );
    }
  };

  const onScroll = () => {
    if (!contentRef.current) return;
    const scrollTop = contentRef.current.scrollTop;
    const scrollHeight = contentRef.current.scrollHeight;
    const clientHeight = contentRef.current.clientHeight;
    const bottom = Math.floor(scrollHeight - scrollTop) <= clientHeight;
    setIsScrolledToBottom(bottom);
  };
  useEffect(() => {
    const current = contentRef.current;
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    window.addEventListener("resize", onScroll);

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", onScroll);
    }

    // Cleanup
    return () => {
      window.removeEventListener("resize", checkOverflow);
      current?.removeEventListener("scroll", onScroll);
    };
  }, []); // Dependency array includes `myContent` to recheck when content changes

  return (
    <div className="md:w-full md:h-full md:flex md:justify-center md:items-center overflow-hidden">
      <div className="flex flex-col text-center items-center p-8 md:p-10 gap-8 font-mono text-stone-800 md:bg-slate-200 md:w-3/4 md:h-3/4 md:shadow-lg md:rotate-[-3deg] overflow-hidden">
        <div className="flex items-center justify-center gap-8">
          <a href="https://twitter.com/0xhank" target="_blank" rel="noreferrer">
            <img
              src={pfp}
              className="h-24 events-none hover:scale-105 transition-all"
              alt="logo"
            />
          </a>
          <div className="flex flex-col gap-2 items-center">
            <span className="text-5xl font-mono bold">0xhank</span>
            <div className="flex flex-row items-center gap-3 h-8">
              <a
                className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                href="https://github.com/0xhank"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub color="#413736" />
              </a>

              <a
                className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                href="https://twitter.com/0xhank"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter color="#413736" />
              </a>

              <a
                className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                href="https://medium.com/@0xhank"
                target="_blank"
                rel="noreferrer"
              >
                <FaMedium color="#413736" />
              </a>

              <a
                className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                href="mailto:zeroxhank@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <FaEnvelope color="#413736" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <a
            href="https://www.primodium.com/"
            className="hover:scale-105 transition-all"
          >
            Building Primodium
          </a>
          <div className="text-xs flex flex-col md:flex-row px-2 md:gap-2 italic text-stone-600">
            <p className="not-italic">prev</p>
            <a
              href="https://www.projectawakening.io/"
              className="hover:scale-105 transition-all"
            >
              CCP Games
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="https://darkseas.me/"
              className="hover:scale-105 transition-all"
            >
              Dark Seas
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="https://0xparc.org/blog/aw-residency"
              className="hover:scale-105 transition-all inline"
            >
              0xPARC AW Resident
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="http://www.dfdao.xyz/"
              className="hover:scale-105 transition-all inline"
            >
              dfdao
            </a>
          </div>
        </div>
        <div
          ref={contentRef}
          className="w-full md:w-7/8 m-auto mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 overflow-y-auto overflow-x-hidden custom-scrollbar p-2"
        >
          {myContent
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((content) => (
              <LinkComponent key={content.name} data={content} />
            ))}
          {isOverflowing && (
            <span
              className={`absolute bottom-2 right-2 text-lg transition-all
          ${!isScrolledToBottom ? "" : "translate-y-10"}`}
            >
              ↓
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
